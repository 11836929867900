<template>
  <div class="store__currency">
    <v-row class="my-7 mx-0">
      <v-col cols="12" md="4">
        <h3>Scope not served</h3>
      </v-col>
      <v-col cols="12" md="8">
        <v-row class="elevation-form px-2 py-2">
          <v-col cols="12">
            <div class="d-flex align-center">
              <h4>Countries</h4>
            </div>
            <v-text-field readonly value="USA"></v-text-field>
            <h4>Sates</h4>
            <v-autocomplete
              v-model="$parent.general.blacklistStates"
              :items="listState"
              item-text="name"
              item-value="value"
              multiple
              clearable
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      listState: [],
    };
  },
  created() {
    this.getStates();
  },
  methods: {
    getStates() {
      axios
        .get(
          'https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-state&rows=-1',
        )
        .then(response => {
          this.listState = response.data.records.map(item => {
            return {
              name: item.fields.ste_name,
              value: item.fields.ste_stusps_code,
            };
          });

          this.listState.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        });
    },
  },
};
</script>
