<template>
  <div class="store__detail">
    <v-row class="my-7 mx-0">
      <v-col cols="12" md="4">
        <h3>Store Details</h3>
        <p>Dcomcy and your customers will use this information to contact you.</p>
      </v-col>
      <v-col cols="12" md="8">
        <v-row class="elevation-form px-2 py-2">
          <v-col cols="12">
            <h4>Store name</h4>
            <v-text-field v-model="$parent.general.storeDetail.name"></v-text-field>
          </v-col>
          <v-col cols="6">
            <h4>Account Email</h4>
            <v-text-field v-model="$parent.general.storeDetail.accountEmail"></v-text-field>
            <small>We'll use this address if we need to contact you about your account</small>
          </v-col>
          <v-col cols="6" v-if="usingLatteX == false">
            <h4>Customer Email</h4>
            <v-text-field v-model="$parent.general.storeDetail.customerEmail"></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-7 mx-0">
      <v-col cols="12" md="4">
        <h3>Telegram Notification</h3>
        <p>Push message new orders, error, abandoned checkout to telegram group</p>
      </v-col>
      <v-col cols="12" md="8">
        <v-row class="elevation-form px-2 py-2">
          <v-col cols="12">
            <p>Please add @dcomcy_bot to your group to get the group id</p>
            <v-switch
              :label="`Push notification when store have new order`"
              v-model="$parent.general.storeDetail.telegramJob.newOrder"
              inset
              color="primary"
              outlined
              style="margin-top: 0 !important"
            ></v-switch>
            <v-switch
              :label="`Push notification when store is error`"
              v-model="$parent.general.storeDetail.telegramJob.storeError"
              inset
              color="primary"
              outlined
              style="margin-top: 0 !important"
            ></v-switch>
            <v-switch
              :label="`Push notification when store have abandoned order`"
              v-model="$parent.general.storeDetail.telegramJob.newAbandonedCheckout"
              inset
              color="primary"
              outlined
              style="margin-top: 0 !important"
            ></v-switch>
          </v-col>
          <v-col cols="12">
            <h4>Push order to Telegram Group ID</h4>
            <v-text-field
              v-model="$parent.general.storeDetail.telegramGroupIds"
              placeholder="id1,id2,..."
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
export default {
  data() {
    return {
      usingLatteX: false,
    };
  },
  computed: {
    ...mapGetters(['user']),
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
    }),
  },
  mounted() {
    if (this.$parent.general) {
      let pmChoice = this.$parent.general.paymentChoice?.type || 'seller';
      this.usingLatteX = pmChoice === 'latte' ? true : false;
    }
  },
};
</script>
